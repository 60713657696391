import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Dans l’enfer de @font-face",
  "description": "Gérer les fontes n’a vraiment rien de simple, surtout si on supporte IE8.",
  "date": "2014-01-30T00:00:00.000Z",
  "path": "/articles/dans-l-enfer-de-font-face/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`L’utilisation de fontes personnalisées a explosé ces dernières années sur le web grâce à la déclaration CSS `}<code parentName="p" {...{
        "className": "text"
      }}>{`@font-face`}</code>{`, dont l’utilisation a été rendue simple par des sites tels que `}<a parentName="p" {...{
        "href": "http://www.fontsquirrel.com/"
      }}>{`FontSquirrel`}</a>{` et son désormais célèbre webfont generator. Pourtant, la méthode proposée ne me paraît pas idéale.`}</p>
    <p>{`Si l’on reprend l’exemple de FontSquirrel, le code généré par le kit téléchargé est le suivant :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@font-face`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-family`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'merriweather'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`src`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token url"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string url"
            }}>{`'merriweather.eot'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`src`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token url"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string url"
            }}>{`'merriweather.eot?#iefix'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`format`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'embedded-opentype'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token url"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string url"
            }}>{`'merriweather.woff'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`format`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'woff'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token url"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string url"
            }}>{`'merriweather.ttf'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`format`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'truetype'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token url"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string url"
            }}>{`'merriweather.svg#merriweatherheavy'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`format`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'svg'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-weight`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` normal`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` normal`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Lorsque l’on veut définir différents types de cette police, on spécifie une `}<code parentName="p" {...{
        "className": "text"
      }}>{`@font-face`}</code>{` propre à chacun (merriweather-italic, merriweather-bold etc.). Puis dans notre CSS, par exemple :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`strong`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-family`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` merriweather-bold`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Serif`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-weight`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` normal`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Le hic avec cette façon de faire, c’est qu’en cas d’impossibilité pour le navigateur d’afficher les fontes personnalisées, la police de `}<em parentName="p">{`fallback`}</em>{` s’affichera sans italique ou sans gras.`}</p>
    <h2>{`Une autre solution ?`}</h2>
    <p>{`En fouillant un peu, j’ai trouvé cette `}<a parentName="p" {...{
        "href": "http://www.metaltoad.com/blog/how-use-font-face-avoid-faux-italic-and-bold-browser-styles"
      }}>{`solution`}</a>{`, bien plus intéressante car elle évitera la suppression ou le passage en `}<em parentName="p">{`faux`}</em>{` (c’est-à-dire simulé par le navigateur) des styles italiques et gras :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@font-face`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-family`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Whatever'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`src`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token url"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string url"
            }}>{`'whatever.eot'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-weight`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` normal`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` normal`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@font-face`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-family`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Whatever'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`src`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token url"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string url"
            }}>{`'whatever-italic.eot'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-weight`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` normal`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` italic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@font-face`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-family`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Whatever'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`src`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token url"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string url"
            }}>{`'whatever-bold.eot'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-weight`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bold`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` normal`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`On utilise un seul nom unique de `}<code parentName="p" {...{
        "className": "text"
      }}>{`font-family`}</code>{` pour toutes nos `}<code parentName="p" {...{
        "className": "text"
      }}>{`@font-face`}</code>{`, puis on y applique nos `}<code parentName="p" {...{
        "className": "text"
      }}>{`font-weight`}</code>{` et `}<code parentName="p" {...{
        "className": "text"
      }}>{`font-style`}</code>{` désirés. Problème réglé… Du moins sur la plupart des navigateurs.`}</p>
    <h2>{`IE, l’éternel vilain petit canard`}</h2>
    <p>{`Comme d’habitude, là où ça coince, c’est avec Internet Explorer (du moins jusqu’à la version 8). La raison, c’est que ce cher IE ne prend pas en compte `}<code parentName="p" {...{
        "className": "text"
      }}>{`font-style`}</code>{` et `}<code parentName="p" {...{
        "className": "text"
      }}>{`font-weight`}</code>{` dans la déclaration `}<code parentName="p" {...{
        "className": "text"
      }}>{`@font-face`}</code>{` (`}<a parentName="p" {...{
        "href": "http://itsravenous.com/blog/banishing-faux-italic-and-faux-bold--on-css3-fonts-in-IE-8-and-below"
      }}>{`source`}</a>{`).`}</p>
    <p>{`Pour contourner le problème, il va falloir avoir recours aux commentaires conditionnels et une feuille de styles séparés pour IE. C’est dans celle-ci qu’on intégrera les polices sur le format proposé par FontSquirrel, avec un nom de `}<code parentName="p" {...{
        "className": "text"
      }}>{`font-family`}</code>{` par type de police. `}<em parentName="p">{`Problem solved`}</em>{` !`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      